import type { WatchStopHandle } from "vue"
import { useDYComponent } from "./dy-component"
import { uniqueId } from "../libs/utils"

/**
 * VOverlay 기반 컴퍼넌트(VOverlay, VDialog, VNavigationDrawer 등)가 브라우저의 뒤로 가기 버튼으로 닫힐 수 있게 해주는
 * 기능을 담당한다.
 * 
 * @param model VOverlay 기반 컴퍼넌트에 연결되는 model 값.
 * @param onHashRemoved 뒤로 가기 이벤트가 발생하여 해시가 제거되었을 때 실행시킬 기능. emit을 통해 넘겨진 model이
 * false로 변하게끔 해야 한다. emit은 컴퍼넌트에 의해 자체적으로 생성되는 별도의 타입으므로 타입을 특정하여 전달할 수
 * 없기 때문에 부득이 사용하는 컴퍼넌트에서 직접 emit을 호출할 수 있도록 이렇게 함수형 인자로 받는다.
 */
export function useDYClosableByBack(model: ComputedRef<boolean> | Ref<boolean>, onHashRemoved: () => void) {
  const { $onActivated, $onDeactivated } = useDYComponent()

  const route = useRoute()
  const router = useRouter()

  const dialogId = uniqueId()

  const initialModelValue = model.value
  const initialRouteKey = route.meta.key

  let unwatchDialogControl: WatchStopHandle | undefined
  let unwatchHash: WatchStopHandle | undefined

  $onActivated(async () => {
    await nextTick()
    
    unwatchDialogControl = watch(model, async (value) => {
      if (value) { // 열리는 경우
        await router.push({ hash: `#${dialogId}`, query: route.query, path: route.path })
        unwatchHash = watch(() => route.hash, (hash) => {
          if (!hash) {
            onHashRemoved()
          }
        })
      } else { // 닫히는 경우
        if (route.hash) {
          router.back()
        }
      }
    })

    // activated 시점에 이미 열려있는 상황일 수도 있으므로 필요함
    if (initialModelValue) {
      unwatchHash = watch(() => route.hash, (hash) => {
        if (!hash) {
          onHashRemoved()
        }
      })
      await router.push({ hash: `#${dialogId}`, query: route.query, path: route.path })
    }
  })

  $onDeactivated(() => {
    onHashRemoved() // 페이지가 이동되면 해당 컴퍼넌트는 deactivated 상태가 되는데 그에 맞춰서 닫혀야 함.

    if (unwatchDialogControl) {
      unwatchDialogControl()
      unwatchDialogControl = undefined
    }

    if (unwatchHash) {
      unwatchHash()
      unwatchHash = undefined
    }
  })
}